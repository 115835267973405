import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import { deleteUsuarioLogueado, saveUsuarioLogueado } from "./actions";

export const usuarioLogueadoReducer = createReducer(
  initialState,
  on(saveUsuarioLogueado, (state, props) => {
    if (state != null) return { ...state, ...props.usuario };
    return { ...props.usuario }
  }),
  on(deleteUsuarioLogueado, () => {
    return null
  })
);
