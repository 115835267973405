<ng-http-loader
    [backgroundColor]="loaderColor"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    [opacity]=".6"
    [spinner]="spinkit.skSpinnerPulse"
    [filteredUrlPatterns]="filteredUrlPatterns"
    >
</ng-http-loader>
<router-outlet></router-outlet>
