import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MensajesService } from '@services/mensajes.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private mensajesService: MensajesService
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error : HttpErrorResponse )=>{
          let mensajeError = '';

          if(!window.navigator.onLine){
            mensajeError =  `Su navegador no esta conectado a internet.`;
          }else{
            mensajeError = error.error.mensaje_error ?? `Ha ocurrido un error.`;
          }

          if(!this.estaEnListaIgnorar(request.url)) this.mensajesService.mensajeError(mensajeError);
          console.error(error);

          return throwError(error.error)
        })
      );
  }

  listaRutasIgnorar: string[] = []

  private estaEnListaIgnorar(url: string): boolean {
    return Boolean(this.listaRutasIgnorar.filter((r) => url.includes(r)).length)
  }
}
