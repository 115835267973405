import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutentificacionService } from '@services/autentificacion.service';
import { MensajesService } from '@services/mensajes.service';
import { ApiCustomErrorCode } from 'enum/api-custom-error-code.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private autentificacionService: AutentificacionService,
    private mensajesService: MensajesService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res) => {

        if (res.type == 4 && res.body.status && res.body.status == 'error') {
          if (res.body.codigo && res.body.codigo == ApiCustomErrorCode.AUTENTIFICACION) {
            this.mensajesService.mensajeError(res.body.mensaje_error);
            this.autentificacionService.expulsar();
          } else if (res.body.codigo && res.body.codigo == ApiCustomErrorCode.PERMISOS) {
            this.mensajesService.mensajeError(res.body.mensaje_error);
          }
        }

        return res
      }));
  }
}
