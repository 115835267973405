import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AutentificacionService } from '@services/autentificacion.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {

  constructor(
    private router:Router, 
    private autentificacionService:AutentificacionService,
    private toastr : ToastrService ){}

  canActivate(){
    
    if(this.autentificacionService.estaLogueado()){
        
      return true;
        
    }else{
      this.autentificacionService.expulsar()
      return false;
    }
  }
  
}
